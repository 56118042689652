import React from "react";
import { AppProps } from "next/app";
import "tailwindcss/tailwind.css";
import "@styles/global.scss";
import "rc-tooltip/assets/bootstrap_white.css";
import { Provider } from "react-redux";
import store from "@redux/store";
import DomainWrapper from "@components/layouts/domainWrapper";
import { DomainProvider } from "@hooks/useDomainContext";
import { FormProvider } from "@hooks/useFormContext";
import { PageProps } from "@services/initial-calls";
import NextErrorComponent from "next/error";
import OutBrain from "@components/shared/outBrain";
import { CallCampaignProvider } from "@hooks/useCallCampaignContext";

export interface CustomAppProps<P = PageProps> extends AppProps<P> {
    pageProps: PageProps;
}

function MyApp({ Component, pageProps }: CustomAppProps): JSX.Element {
    if (pageProps.error) {
        return <NextErrorComponent statusCode={pageProps.error.statusCode} />;
    }

    return (
        <DomainProvider {...pageProps}>
            <FormProvider>
                <div id="domain-wrapper">
                    <DomainWrapper
                        domain={pageProps.domain}
                        pageTitle={pageProps.pageTitle}
                    >
                        <CallCampaignProvider>
                            <Provider store={store}>
                                <OutBrain />
                                <Component {...pageProps} />
                            </Provider>
                        </CallCampaignProvider>
                    </DomainWrapper>
                </div>
            </FormProvider>
        </DomainProvider>
    );
}

export default MyApp;
